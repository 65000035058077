.filters {
  display: flex;
  overflow: hidden;
  transition: width ease-in-out .2s;

  .collapsible {
    &:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    height: 1px;
    width: 90%;
    transform: translateX(-50%);
    border-bottom: solid 1px $border;
    }
  }

  .collapsible__actions {
    .tag-button {
      padding: 20px 0;
    }
  }
}

.filters__column {
  @extend .list--raw;
  width: 110px;
  min-width: 110px;
  position: relative;
  z-index: 10;
  background-color: $background;
  border-right: solid 1px $border;
}

.filters__column--large {
  height: 100%;
  width: 290px;
  min-width: 290px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
  overflow-y: auto;
  animation: slideInRight ease-in-out .2s;

  .filters__item {
    margin-right: 0;
    padding: 20px;
    height: unset;
    position: relative;
    flex-direction: column;

    &:last-of-type {
      &:after {
        display: none;
      }
    }

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 0;
      height: 1px;
      width: 90%;
      transform: translateX(-50%);
      border-bottom: solid 1px $border;
    }

    > button { border-bottom: none; }
  }

  button {
    &.include {
      color: $primary;

      &:hover,
      &:active,
      &:focus { color: $primary; }
    }
    &.exclude {
      color: $danger;

      &:hover,
      &:active,
      &:focus { color: $danger; }
    }
  }

}

.filters__item {
  margin-right: -1px;
  height: 61px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  text-align: center;
  border-right: solid 1px transparent;
  border-left: solid 1px transparent;

  &.active {
    border-right-color: $primary;
  }

  > button {
    margin: 0 15px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $text--light;
    border-bottom: solid 1px $border;

    &:hover,
    &:active,
    &:focus {
      color: $text;
      text-decoration: none;
    }
  }
}

.search-filters {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
  
  .filters__inline {
    flex: 1;
    max-width: 20%;
  
    .filters__inline__radios {
      display: flex;
      gap: 2px;
      flex-wrap: wrap;
  
      &.filters__inline__radios--tempos {
        margin-top: 15px;
      }
    }
  }
}

.filters__button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  margin: 0;
  background-color: transparent;
  color: $filters;
  transition: background-color 0.2s ease, color 0.2s ease;

  &.filters__button--active {
    background-color: $filters;
    color: #fff;
  }
}



.filters__label {
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $text--light;
}


@media (max-width: $screen-lg) {
  .filters {
    width: 100%;
  }

  .filters__column--large {
    max-height: unset;
    flex: 1;
    transform: none;
    border-right: none;
  }
}
