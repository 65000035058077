.menu {
  padding: 0 25px;
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.menu__section {
  @extend .list--raw;
  height: 100%;
  display: flex;

  li {
    height: 100%;
    display: flex;
    align-items: center;

    &.current {
      a {
        color: $primary;
      }
    }

    &:first-of-type {
      a { padding-left: 0; }
    }

    &:last-of-type {
      a { padding-right: 0; }

      .icon--cart {
        font-size: 2.2rem;
        transform: translateY(-50%);
      }
    }

    a {
      padding: 0 12px;
      font-size: 1.6rem;

      &.mobile {
        display: none;
      }
    }
  }
}

@media (max-width: $screen-xxl) {
  .menu {
    padding: 0 30px;
    height: unset;
    width: 100%;
    position: absolute;
    top: 60px;
    flex-direction: column;
    background: #fff;
    max-height: 0;
    transition: max-height ease-in .2s;
    box-shadow: 0 10px 10px 0 rgba(5, 16, 43, 0.05);
    overflow: hidden;
  }

  .menu__section {
    flex-direction: column;

    &:first-of-type { padding-top: 12px; }
    &:last-of-type { padding-bottom: 12px; }

    li {
      a { padding: 7px 0; }
    }
  }

  .menu--open {
    max-height: 500px;
    border-bottom: solid 1px $border;
    overflow-y: auto;
  }
}
