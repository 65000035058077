.result-tabs {
  padding: 0px 25px;
  width: 100%;
  position: absolute;
  top: 130px;
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, .96);
  z-index: 150;

  .tabs { margin-bottom: 0; }

  .tabs-content { padding: 0 0 20px; }

  .collapsible {
    color: $text--light;
    border-bottom: solid 1px $border;

    &:last-of-type { border-bottom: none; }
  }

  .collapsible__actions {
    padding: 15px 0;
  }
}
