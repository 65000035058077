.navbar {
  padding: 0;
  height: 60px;
  min-height: 60px;
  display: flex;
  background: #fff;
  border-bottom: solid 1px $border;
  z-index: 300;

  .dropover {
    height: 100%;
    border-left: solid 1px $border;

    /* TODO: Amélie-orer les styles */
    &.dropover--bright {
      border-right: solid 1px $border;
    }
  }

  .dropover__menu {
    padding: 20px;
    min-width: 230px;
    top: 15px;
    right: 15px;
  }
}

.navbar__brand {
  margin: 0;
  padding: 0;
  min-width: 110px;
  height: calc(100% - 14px);
  display: flex;
  justify-content: center;
  align-items: center;
  background: center url('../../images/logo.svg') no-repeat;
  border-right: solid 1px $border;
}

.navbar__burger {
  display: none;
}

@media (max-width: $screen-xxl) {
  .navbar__burger {
    padding: 0 20px;
    height: 100%;
    display: flex;
    align-items: center;
    color: $brand;
    font-size: 2.5rem;
  }

  .navbar--minimal {
    .navbar__brand {
      position: static;
    }
  }
}
