.library-searching {
  padding-top: 3rem;
}

.library-default {
  .subscription-banner {
    margin-top: 3rem;
  }
  .tile {
    width: calc(20% - 20px);
    .tile__image {
      height: unset;
      .image {
        aspect-ratio: 1/1;
      }
    }
  }
}

@media(max-width: $screen-lg) {
  .library-default {
    .tile {
      margin: 0 10px 40px !important;
      width: calc(50% - 20px);
    }
  }
}

@media(max-width: $screen-sm) {
  .library-default {
    .tile {
      margin: 0 10px 30px !important;
      width: 100%;
    }
  }
}
