.checklist {
  @extend .list--raw;
  padding: 50px !important;
  flex: 1;
  border-right: solid 1px $border--light;

  &:last-of-type { border: none; }
}

.checklist__item {
  margin-bottom: 20px;
  padding-left: 50px;
  position: relative;
  font-size: 1.8rem;
  color: $text--light;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    font-family: 'Icomoon';
    font-size: 1.6rem;
    color: $primary;
    content: '\e901';
  }

  &:last-of-type { margin-bottom: 0; }

  a {
    text-decoration: underline;
  }
}

@media (max-width: $screen-lg) {
  .checklist {
    padding: 40px !important;
  }
}

@media (max-width: $screen-md) {
  .checklist__item {
    padding-left: 30px;
    margin-bottom: 13px;
    font-size: 1.6rem;
  }
}

@media (max-width: $screen-sm) {
  .checklist {
    padding: 0 40px !important;
    border: none;

    &:first-of-type {
      padding-top: 40px !important;
      padding-bottom: 15px !important;
    }

    &:last-of-type { padding-bottom: 40px !important; }
  }
}
