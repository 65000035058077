.player {
  height: 100px;
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  background: #fff;
  box-shadow: $shadow--top;
  border-top: solid 1px $border--light;
  z-index: 350;
}

.player__details {
  padding: 0 20px;
  width: 400px;
  min-width: 200px;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  color: $text--light;
  border-right: solid 1px $border;

  .info {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .title {
    font-family: 'Aktiv Grotesque Bold', sans-serif;
    color: $text;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.player__controls {
  padding: 0 35px;
  display: flex;
  align-items: center;
  border-right: solid 1px $border;

  .action-button {
    margin: 0 20px;
  }

  .button-random {
    margin: 0 10px;
  }

  .button-loop {
    margin: 0 20px 0 10px;
  }

  .icon--shuffle--active, .icon--loop--active {
    color: $primary--light;
  }
}

.player__waveform {
  padding: 0 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  border-right: solid 1px $border;

  .seeker {
    margin: 0 15px;
    cursor: pointer;
  }
}

.player__actions {
  padding-left: 30px;
  padding-right: 105px;
  display: flex;
  align-items: center;

  .action-button {
    margin: 0 3px;
  }
}

.player__progress {
  position: absolute;
  bottom: 0;
  height: 4px;
  width: 100%;
  background: $border--dark;

  .progress {
    height: 4px;
    position: absolute;
    bottom: 0;
    background: $primary;
  }
}

.player--mobile {
  display: none;
}

@media (max-width: $screen-xxl) {
  .player__details {
    width: unset;
    border: none;
    flex: 0;
  }

  .player__controls {
    padding: 0;
    border: none;
    flex: 0;
  }

  .player__waveform {
    padding: 0 20px;
    border: none;
    flex: 1;

    .waveform {
      width: 100%;
    }
  }

  .player__actions {
    padding-left: 0;
    flex: 0;
  }
}

@media (max-width: $screen-lg) {
  .player { display: none; }
  .player--mobile { display: flex; }

  .player__details {
    padding: 0 30px;
    flex: 1;
    border: none;

    .cover:not(.cover--play),
    .cover:not(.cover--pause) {
      cursor: default;
    }
  }

  .player__actions {
    padding-left: 0;
    padding-right: 40px;
  }
}
