.licence-offer {}

.licence-offer__help {
  margin: 0 0 3.5rem;
  font-family: 'Aktiv Grotesque Bold', sans-serif;
}

.licence-offer__title {}

.licence-offer__content {
  .item {
    margin: 0 0 3rem;
    color: $text;
    background: #fff;
    box-shadow: $shadow;
    border-radius: 10px;

    h3 {
      margin-bottom: 2rem;
      padding: 1.5rem 3rem;
      font-size: 1.6rem;
      border-bottom: solid 1px $border--light;
    }

    .item__content {
      padding: 0 3rem;
      line-height: 2.2;
    }

    .btn {
      margin-top: 3rem;
      width: 100%;
      justify-content: center;
      border-radius: 0 0 10px 10px;
    }
  }
}
