.modal {
  max-height: 550px;
  width: 100%;
  max-width: 1000px;
  position: fixed;
  display: none;
  flex-direction: column;
  background: #fff;
  border-radius: 7px;
  box-shadow: $shadow;
  overflow: hidden;
  z-index: 1000;
}

.modal__header,
.modal__footer {
  padding: 15px 25px;
  height: 70px;
  position: relative;
  display: flex;
  align-items: center;
  box-shadow: $shadow--light;
  z-index: 100;
}

.modal__header {
  .title {
    font-family: 'Aktiv Grotesque Bold', sans-serif;
    font-size: 1.8rem;

    .icon { margin-right: 8px; }
  }

  button {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    font-size: 1.3rem;

    &:hover,
    &:active,
    &:focus {
      color: darken($text, 10%);
      transition: ease-in-out .15s;

      .icon {
        border-color: darken($text, 10%);
        transition: ease-in-out .15s;
      }
    }

    .icon {
      border: solid 1px transparent;
      padding: 7px;
      border-radius: 50%;
    }
  }
}

.modal__content {
  flex: 1;
  background-color: $background;
  overflow: auto;

  .filters {
    height: 100%;
  }
}

.modal__footer {
  justify-content: flex-end;

  .btn {
    margin: 0 5px;

    &:first-of-type { margin-left: 0; }
    &:last-of-type { margin-right: 0; }
  }
}

.modal--show {
  display: flex;
  animation: slide-center .3s ease-in-out both;
}

@media (max-width: $screen-lg) {
  .modal {
    height: 100vh;
    max-height: unset;
    width: 100vw;
    border-radius: 0;
  }
}
