button {
  padding: 0;
  background: none;
  border: none;

  &:focus {
    outline: none;
  }

  .icon {
    margin-right: 7px;
  }
}
