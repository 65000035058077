.form-page {
  .h1 { margin: 60px 0 30px; }

  .btn {
    max-width: 500px;
    margin: 30px auto 35px;
  }
}

.form-page__title {
  margin-bottom: 50px;
  padding: 60px 0 25px;
  font-family: 'Aktiv Grotesque Bold', sans-serif;
  font-size: 2.4rem;
  color: $text;
  border-bottom: solid 1px $border;
}

@media (max-width: $screen-sm) {
  .form-page__title {
    padding: 25px 0 20px;
    font-size: 1.8rem;
  }
}
