.btn {
  color: $text;
  border: solid 1px $border--dark;
  padding: 0 27px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  border-radius: 50px;
  transition: border-color ease-in-out .15s, background-color ease-in-out .15s;

  &:hover,
  &:active,
  &:focus {
    color: $text;
    border-color: $text;
    text-decoration: none;
  }

  .icon {
    margin-right: 7px;
  }

  &.disabled {
    color: $disabled;
    border-color: $disabled;
  }
}

.btn--primary {
  color: #fff;
  border-color: $primary--light;
  background-color: $primary--light;

  &:hover,
  &:active,
  &:focus {
    color: #fff;
    border-color: darken($primary--light, 5%);
    background-color: darken($primary--light, 5%);
  }

  &.disabled {
    color: #fff;
    background-color: $disabled;
    border-color: $disabled;
  }
}

.btn--secondary {
  background-color: #fff;
  border-color: $primary--light;

  &:hover,
  &:active,
  &:focus {
    border-color: darken($primary--light, 5%);
  }

  &.disabled {
    background-color: $disabled;
    border-color: $disabled;
  }
}

.btn--light {
  color: rgba(255, 255, 255, .85);
  border-color: rgba(255, 255, 255, .85);
  background-color: transparent;

  &:hover,
  &:active,
  &:focus {
    color: rgba(255, 255, 255, 1);
    border-color: rgba(255, 255, 255, 1);
  }

  &.disabled {
    color: $disabled;
    border-color: $disabled;
  }
}

.btn--success {
  color: rgba($success, .85);
  border-color: rgba($success, .85);
  background-color: transparent;

  &:hover,
  &:active,
  &:focus {
    color: rgba($success, 1) !important;
    border-color: rgba($success, 1);
  }

  &.disabled {
    color: $disabled;
    border-color: $disabled;
  }
}

.btn--success--full {
  color: #fff;
  background-color: $success;
  border: none;

  &:hover,
  &:active,
  &:focus {
    color: #fff;
    border-color: darken($success, 5%);
    background-color: darken($success, 5%);
  }

  &.disabled {
    color: $disabled;
    border-color: $disabled;
  }
}

.btn--danger {
  color: rgba($danger, .85);
  border-color: rgba($danger, .85);
  background-color: transparent;

  &:hover,
  &:active,
  &:focus {
    color: rgba($danger, 1);
    border-color: rgba($danger, 1);
  }

  &.disabled {
    color: $disabled;
    border-color: $disabled;
  }
}

.btn--full {
  width: 100%;
  justify-content: center;
}

.btn--large {
  padding: 0 50px;
}

.btn--small {
  height: 38px;
}

.btn + .btn {
  margin-left: 10px;
}
