.services {
  @extend .card;
  @extend .card--rounded;

  margin: 0 auto;
  padding: 70px 0;
  max-width: 1000px;
}

.services__title {
  margin: 0 0 50px;
  max-width: unset;
}

.services__content {
  display: flex;
  justify-content: space-between;

  .info {
    padding: 0 50px;
    flex: 1;
    text-align: center;
    border-right: solid 1px $border--light;

    &:last-of-type { border: none; }
  }
}

@media (max-width: $screen-sm) {
  .services {
    padding: 50px 0;
  }

  .services__title {
    margin: 0 0 35px;
  }

  .services__content {
    flex-direction: column;

    .info {
      margin-bottom: 35px;
      padding: 0 20px;
    }
  }
}
