.features {
  margin-bottom: 150px;
  display: flex;

  ul {
    @extend .list--raw;
  }
}

.features__item {
  margin: 0 0 130px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  &:after {
    position: absolute;
    content: '';
    border-radius: 50%;
  }

  &:nth-of-type(1) {
    &:after {
      height: 45px;
      width: 45px;
      top: 10px;
      left: 520px;
      background: $brand;
    }
  }

  &:nth-of-type(2) {
    &:after {
      height: 115px;
      width: 115px;
      top: -102px;
      left: -40px;
      background: $primary;
    }
  }

  &:nth-of-type(3) {
    &:after {
      height: 35px;
      width: 35px;
      top: -20px;
      left: 370px;
      background: $brand;
    }
  }

  .detail {
    margin: 0 75px;
    width: 100%;
    max-width: 600px;
    color: $text--light;
    z-index: 1;

    h2 {
      align-items: flex-start;
      margin-bottom: 50px;
    }
  }

  .image {
    margin: 0;
    width: 550px;
    min-width: 550px;
    z-index: 1;
  }
}

@media (max-width: $screen-xxl) {
  .features__item {
    .image {
      width: 400px;
      min-width: 400px;
    }
  }
}

@media (min-width: $screen-lg) {
  .features__item--reverse {
    flex-direction: row-reverse;
  }
}

@media (max-width: $screen-lg) {
  .features__item {
    margin: 0 0 60px;
    flex-direction: column;

    .detail {
      margin: 0;
      width: 100%;
      max-width: unset;
      text-align: center;

      h2 {
        max-width: unset;
        align-items: center;
        text-align: center;
      }
    }

    .image {
      margin: 0 auto;
      width: 100%;
      max-width: 500px;
    }
  }
}

@media (max-width: $screen-md) {
  .features {
    margin-bottom: 35px;
  }

  .features__item {
    &:nth-of-type(1),
    &:nth-of-type(3) {
      &:after {
        height: 25px;
        width: 25px;
        left: 200px;
      }
    }
  }
}

@media (max-width: $screen-sm) {
  .features__item {
    margin: 0 0 30px;
  }
}
