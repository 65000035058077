.taglist {}

.taglist__title {
  margin: 60px 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.taglist__row {
  padding-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
