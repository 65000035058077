.disabled,
[disabled] {
  color: $disabled;
  pointer-events: none;
}

[readonly] {
  color: $readonly !important;
  background-color: $bg-readonly !important;
  pointer-events: none;
}

.screen-reader {
  width: 1px;
  height: 1px;
  position: absolute;
  left: -10000px;
  top: auto;
  overflow: hidden;
}

.no-scroll { overflow: hidden; }

.text-mute { color: $text--light !important; }
.text-danger { color: $danger !important; }
.text-primary { color: $primary !important; }

.text-overline { text-decoration: line-through !important; }

.text-ellipsis {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block !important;
}

.no-underline { text-decoration: none !important; }

.xs-on,
.sm-on,
.md-on,
.lg-on,
.xxl-on {
  display: none !important;
}

.xs-off,
.sm-off,
.md-off,
.lg-off,
.xl-off {
  display: flex !important;
}

@media (min-width: $screen-xxl) {
  .xxl-on { display: flex !important; }
  .xl-off { display: none !important; }
}

@media (min-width: $screen-lg) and (max-width: $screen-xxl - 1) {
  .lg-on { display: flex !important; }
  .lg-off { display: none !important; }
}

@media (min-width: $screen-md) and (max-width: $screen-lg - 1) {
  .md-on { display: flex !important; }
  .md-off { display: none !important; }
}

@media (min-width: $screen-sm) and (max-width: $screen-md - 1) {
  .sm-on { display: flex !important; }
  .sm-off { display: none !important; }
}

@media (max-width: $screen-sm - 1) {
  .xs-on { display: flex !important; }
  .xs-off { display: none !important; }
}
