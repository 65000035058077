h1 {
  margin: 60px 0 30px;
  font-family: 'Aktiv Grotesque Bold', sans-serif;
  font-size: 2.4rem;
}

.h1 {
  margin: 0;
  font-family: 'Aktiv Grotesque Bold', sans-serif;
  font-size: 2.4rem;
}

h2 {
  margin: 45px 0 15px;
  font-family: 'Aktiv Grotesque Bold', sans-serif;
  font-size: 1.8rem;
}

@media (max-width: $screen-lg) {
  h1 {
    margin: 40px 0 20px;
    font-size: 1.8rem;
  }
}
