.radio {
  margin-bottom: 5px;
  display: flex;

  input[type="radio"] {
    display: none;
  }

  input[type="radio"] + label {
    margin: 0;
    padding-left: 35px !important;
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    color: $text;
    line-height: 23px;
    cursor: pointer;

    &:before {
      content: '';
      width: 23px;
      height: 23px;
      position: absolute;
      left: 0;
      background-color: #fff;
      border: solid 1px $border;
      border-radius: 50%;
    }

    &:after {
      content: '';
    }
  }

  input[type="radio"][disabled] {
    pointer-events: none;

    & + label {
      pointer-events: none;
      color: lighten($text, 20);

      &:before {
        background-color: $disabled;
      }
    }
  }

  input[type="radio"]:checked + label {
    &:after {
      position: absolute;
      top: 6px;
      left: 6px;
      font-family: 'Icomoon';
      font-size: 1.1rem;
      color: $primary;
      content: '\e91a';
      line-height: 1;
    }
  }
}

.radio--inline {
  margin: 0 20px 5px;
  display: inline-flex;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
}
