.track-list {
  background: #fff;
  box-shadow: $shadow;
  overflow-y: auto;

  .table {
    margin: 0;
    box-shadow: none;
    border-bottom: solid 1px $border;

    .duration-cell {
      width: 300px;
      max-width: 300px;
    }

    .duration-input {
      display: flex;
      justify-content: space-between;

      .form__input {
        width: 80px;
        min-width: 80px;
      }

      .form__unit {
        margin: 0 10px;
      }

      .form__error {
        padding: 5px;
        font-size: 14px;
      }
    }

    .action-cell {
      width: 70px;
      text-align: center;
    }
  }
}

.track-list__search {
  background: #fff;
  border-bottom: solid 1px $border;
  border-right: solid 1px $border;

  .form {
    margin: 0;
    position: relative;

    &:before {
      height: 23px;
      width: 23px;
      position: absolute;
      top: 50%;
      left: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Icomoon';
      font-size: 12px;
      content: '\e900';
      border: solid 1px #000;
      border-radius: 50%;
      transform: translateY(-50%);
    }

    input {
      margin: 0;
      padding-left: 50px;
      height: 50px;
      border: none;
      border-radius: 0;
    }
  }

  .result-list {
    list-style: none;
    padding: 20px;
    margin: 0;
    border-top: solid 1px $border;

    .result-list__item {
      margin-bottom: 5px;
      color: $primary;
      cursor: pointer;
      transition: color ease-in .2s;

      &:hover {
        color: darken($primary, 10%);
      }
    }

    .empty {
      color: $text--light;
    }
  }
}

@media (max-width: $screen-lg) {
  .track-list {
    .table {
      .duration-input {
        flex-direction: column;

        .form__input {
          margin-bottom: 7px;
        }
      }
    }
  }
}
