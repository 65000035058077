.tabs {
  height: 55px;
  display: flex;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: auto;

  &::-webkit-scrollbar {
    height: 0 !important;
    width: 0 !important;
  }
}

.tabs__item {
  margin: 0 25px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 15px;
  color: $text;
  line-height: 1;
  white-space: nowrap;
  border-bottom: solid 2px transparent;

  &:first-of-type { margin-left: 0; }
  &:last-of-type { margin-right: 0; }

  &:hover { text-decoration: none; }

  &.active {
    color: $primary;
    border-color: $primary;
    pointer-events: none;
  }

  h1,
  span {
    margin: 0;
    font-family: 'Aktiv Grotesque Bold', sans-serif;
    font-size: 2.4rem;
  }
}

@media (max-width: $screen-xxl) {
  .tabs {
    margin-bottom: 15px;
    width: 100%;
    border-bottom: solid 1px $border;

    h1,
    span {
      font-family: 'Aktiv Grotesque Bold', sans-serif;
      font-size: 1.7rem !important;
    }
  }

  .tabs__item {
    margin: 0 15px;
  }
}
