.waveform {
    display: block;
    position: relative;
    min-width: 300px;
    height: 48px;
}

.waveform__path {
    fill: none;
    stroke: $waveform;
    stroke-width: 1;

  &--bold {
    stroke-width: 2;
  }
}

.waveform__path--played {
  stroke: $waveform--played;
}

.waveform__path--disabled {
    stroke: $waveform--disabled;
}

.waveform--light {
    .waveform__path {
        opacity: 0.4;
    }
}
