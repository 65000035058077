.register {
  padding: 200px 0 100px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .btn {
    min-width: 230px;
    justify-content: center;
  }
}

.register__title {
  margin: 0 0 35px;
  color: #fff;
  text-align: center;

  span {
    margin-bottom: 15px;
    color: transparentize(#fff, .65);
  }
}

@media (max-width: $screen-md) {
  .register {
    padding: 100px 0 50px;
  }
}
