.social {
  display: flex;
}

.social__item {
  margin: 0 4px;
  height: 45px;
  width: 45px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px rgba(255, 255, 255, .2);
  border-radius: 50%;
  transition: color ease-in-out .15s;

  &:hover,
  &:active {
    color: rgba(255, 255, 255, .8);
    text-decoration: none;
  }
}
