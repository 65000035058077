.breadcrumb {
  margin: 60px 25px 0 !important;
  max-width: 100%;
}

@media (max-width: $screen-lg) {
  .breadcrumb {
    margin: 40px 0 !important;
  }
}
