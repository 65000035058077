.header {
  height: 100vh;
  min-height: 700px;
  max-height: 950px;
  font-size: 18px;
  color: transparentize(#fff, .4);
  text-align: center;

  picture {
    img {
      margin: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: center top;
    }
  }

  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.header__content {
  margin: 0 auto;
  max-width: 1100px;
  flex: 1;
  justify-content: center;

  h1 {
    font-size: 60px;
    color: #fff;
    line-height: 1.2;
    text-align: center;
  }

  .actions {
    margin: 45px 0;

    .btn {
      margin: 0 15px;
      display: inline-flex;
      justify-content: center;
    }
  }
}

@media (max-width: $screen-md) {
  .header__content {
    h1 {
      font-size: 35px;
    }

    .actions {
      display: flex;
      flex-direction: column;
      align-items: center;

      .btn {
        margin: 7px 15px;
      }
    }
  }
}

@media (max-width: $screen-md) {
  .header {
    padding-bottom: 0;
    height: unset;
    min-height: unset;
  }
}
