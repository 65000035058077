.thumblist {}

.thumblist__title {
  margin: 60px 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 2.4rem;

  a {
    font-size: 1.6rem;
  }
}

.thumblist__row {
  padding: 0 0 20px;
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: $screen-lg) {
  .thumblist__title { margin: 30px 10px 20px; }
}

@media (max-width: $screen-sm) {
  .thumblist__title {
    margin: 0px 10px 35px;
    font-size: 1.8rem;

    h2 {
      font-size: 1.8rem;
    }
  }
}
