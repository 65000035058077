.link {
  position: relative;
  color: $text;
  display: inline-block;
  color: $text;
  cursor: pointer;
  transition: ease-in-out .15s;

  &:hover,
  &:active {
    color: $primary;
    text-decoration: none;
  }

  .icon {
    margin-right: 10px;

    &:only-of-type {
      margin-right: 0;
    }
  }
}

a,
.link--primary {
  color: $primary;

  &:hover,
  &:active {
    color: darken($primary, 10%);
  }
}

.link--light {
  color: $text--light;
  transition: ease-in-out .15s;

  &:hover,
  &:active {
    color: $primary;
    text-decoration: none;
  }
}

.link--danger {
  color: $danger;

  &:hover,
  &:active {
    color: darken($danger, 10%);
  }
}

.link--underline {
  padding-bottom: 1px;
  line-height: 1;
  border-bottom: solid 1px transparent;

  &:hover,
  &:active,
  &:focus {
    border-color: $primary;
  }
}
