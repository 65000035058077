.footer {
  padding: 110px 0 80px;
  color: rgba(255, 255, 255, .5);
  background-color: $brand;

  .container-fluid {
    margin-bottom: 90px;
    display: flex;
    align-items: flex-start;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  a {
    font-family: 'Aktiv Grotesque Regular', sans-serif;
    color: rgba(255, 255, 255, .5);
    transition: color ease-in-out .15s;

    &:hover,
    &:active {
      color: #fff;
      text-decoration: none;
    }
  }
}

.footer__brand {
  max-width: 360px;
  padding-right: 35px;
  font-size: 1.5rem;
  line-height: 2;
  border-right: solid 1px rgba(255, 255, 255, .1);
}

.footer__nav {
  padding-left: 35px;
  display: flex;
  flex: 1;

  ul {
    @extend .list--raw;
    margin: 10px 30px 0 !important;

    &:first-of-type { margin-left: 0 !important; }
    &:last-of-type { margin-right: 0 !important; }

    li {
      margin-bottom: 7px;
      color: #fff;

      &:first-of-type {
        margin-bottom: 25px;
        font-family: 'Aktiv Grotesque Bold', sans-serif;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      a {
        color: rgba(255, 255, 255, .8);
      }
    }
  }
}

.footer__legals {
  flex: 1;
}

.footer__social {
  @extend .list--raw;
  display: flex;

  li {
    margin: 0 20px;
    display: flex;
    justify-content: center;

    a {
      color: rgba(255, 255, 255, .8);
      transition: color ease-in-out .15s;

      &:hover,
      &:active {
        color: rgba(255, 255, 255, 1);
        text-decoration: none;
      }
    }
  }
}

@media (max-width: $screen-lg) {
  .footer {
    padding: 60px 0 35px;

    .container-fluid {
      flex-direction: column;
      margin-bottom: 60px;

      &:last-of-type {
        flex-direction: column-reverse;
        align-items: center;
      }
    }
  }

  .footer__brand {
    max-width: unset;
    margin: 0 0 35px;
    padding: 0 0 25px;
    border-right: 0;
    border-bottom: solid 1px rgba(255, 255, 255, .1);
  }

  .footer__nav {
    padding: 0;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;

    ul,
    ul:first-of-type,
    ul:last-of-type {
      margin: 0 20px 45px!important;
      max-width: 150px;
    }
  }

  .footer__legals {
    text-align: center;
  }

  .footer__social {
    margin-bottom: 25px !important;
  }
}

@media (max-width: $screen-sm) {
  .footer__nav {
    ul,
    ul:first-of-type,
    ul:last-of-type {
      width: calc(50% - 40px);
    }
  }
}
