.options {}

.options__item {
  padding: 0 6px;
}

.options__item--include {
  color: $disabled;

  &:hover,
  &.active {
    color: $primary;
  }
}

.options__item--exclude {
  color: $disabled;

  &:hover,
  &.active {
    color: $danger;
  }
}
