.chip {
  padding: 2px 6px;
  font-size: 11px;
  color: #fff;
  text-transform: uppercase;
  background: $brand;
  border-radius: 50px;
  
  &.chip--filters {
    transition: color 0.2s ease, background 0.2s ease;
    color: $text;
    background: $background--dark;

    &.chip--active {
      color: #fff;
      background: $filters;
    }
  }
}
