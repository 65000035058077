.media {
  margin: 10px 0;
  display: flex;
  align-items: flex-start;
}

a.media {
  &:hover,
  &:active {
    text-decoration: none;

    .media__info {
      strong {
        text-decoration: underline;
      }
    }
  }
}

.media__cover {
  margin-right: 15px;
  min-width: 40px;
  min-height: 40px;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  border-radius: 7px;
}

.media__info {
  max-width: calc(100% - 55px);
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  color: $text--light;

  strong {
    font-family: 'Aktiv Grotesque Bold', sans-serif;
    font-size: 1.5rem;
    color: $text;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.media__info__subtitle {
  display: flex;

  > *:not(:first-child):before {
    content: '-';
    margin: 0 0.4em;
  }
}

@media (max-width: $screen-sm) {
  .media {
    margin: 15px 0;
  }
}
