.badge {
    position: absolute;
    border-radius: 4px;
    top: 0;
    left: 0;
    padding: 0 0.4em;
    font-size: 13px;
    font-weight: bold;
    transform: translate(-67%, -50%);

    &--new {
        color: white;
        background-color: $new;
    }
    &--right {
        right: 0;
        transform: translate(50%, -50%);
        left: unset;
    }
    &--alert {
        color: white;
        background-color: $danger;
    }
    &--rounded {
        border-radius: 50%;
    }
}

.badge--cart {
  &:after {
    display: flex;
    justify-content: center;
    align-items: center;
    content: attr(data-count);
    height: 16px;
    width: 16px;
    position: absolute;
    bottom: 15px;
    left: 25px;
    font-size: 10px;
    background-color: $danger;
    color: #fff;
    border-radius: 50%;
    z-index: 1;
  }

  &[data-count="0"] {
    &:after {
      display: none;
    }
  }
}
