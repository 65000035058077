.dropover {
  position: relative;
}

.dropover--menu {
  padding: 0 27px;
}

.dropover__button {}

.dropover__button--arrow {
  padding: 0 25px;
  height: 100%;

  &:after {
    margin-left: 10px;
    font-family: 'Icomoon';
    font-size: 1rem;
    content: '\e902';
    border: none;
  }
}

.dropover__menu {
  padding: 20px 30px;
  min-width: 160px;
  max-height: 340px;
  position: absolute;
  top: 0;
  right: 0;
  left: unset;
  display: none;
  white-space: nowrap;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  box-shadow: $shadow;
  z-index: 1;
  overflow-y: auto;

  .link {
    margin: 0 0 10px;
    display: flex;
    align-items: center;

    &:last-child {
      margin: 0;
    }

    .icon {
      margin-right: 9px;
      height: 22px;
      width: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $primary;
      font-size: 12px;
      color: #fff;
      border: solid 1px $primary;
      border-radius: 50px;
    }
  }
}

.dropover--up {
  .dropover__menu {
    top: unset;
    bottom: calc(100% - 50px);
  }
}

.dropover--show {
  .dropover__menu {
    display: block;
  }
}
