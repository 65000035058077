input {
  margin: 0 0 5px;
  padding: 0 10px;
  width: 100%;
  height: 38px;
  display: flex;
  align-items: center;
  color: $text;
  background-color: transparent;
  border: solid 1px $border--dark;
  border-radius: 4px;

  &::placeholder {
    color: $text--light;
    opacity: .4;
  }

  &:focus {
    outline: none;
    border: solid 1px $primary;
    transition: border-color ease-in-out .15s;

    &::placeholder {
      opacity: .25;
      transition: opacity ease-in-out .15s;
    }
  }
}
