.tooltip {
  cursor: pointer;
  position: relative;

  &:before,
  &:after {
    position: absolute;
    display: none;
    z-index: 1;
  }

  &:before {
    margin-bottom: 10px;
    padding: 5px 10px;
    bottom: 100%;
    left: 50%;
    color: #fff;
    font-size: 1.2rem;
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    transform: translateX(-50%);
    background: $tooltip;
    border: none !important;
    border-radius: 6px;
    content: attr(data-tooltip) !important;
  }

  &:after {
    margin-bottom: 4px;
    left: 50%;
    text-align: center;
    white-space: nowrap;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    bottom: 100%;
    border: 6px solid transparent !important;
    border-top-color: $tooltip !important;
    border-bottom: none !important;
    content: '';
  }

  &:hover {
    &:before,
    &:after {
      display: flex !important;
    }
  }

  $tooltip-success: rgba($success, .85);

  &-success {

    &:before {
      background-color: $tooltip-success;
      border-color: $tooltip-success;
    }

    &:after {
      border-top-color: $tooltip-success !important;
    }
  }
}
