.cover {
  margin-right: 20px;
  min-height: 60px;
  min-width: 60px;
  max-height: 60px;
  max-width: 60px;
  position: relative;
  background: url('../../../common/images/placeholder.svg');
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  border-radius: 10px;
  box-shadow: $shadow;
}

.cover--play,
.cover--pause,
.cover--loading {
  &:before {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    background-color: rgba(0, 0, 0, .2);
    border-radius: 10px;
    transition: background-color ease-in-out .2s;
  }

  &:after {
    height: 38px;
    width: 38px;
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Icomoon';
    font-size: 1.2rem;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: $shadow--bottom;
    transform: translate(-50%, -50%);
  }
}

.cover--play:after {
  content: '\e908';
}

.cover--pause:after {
  content: '\e907 ';
}

.cover--loading:after {
  content: '\e912';
}

.cover--mini {
  margin: 0;
  min-height: 40px;
  min-width: 40px;
  max-height: 40px;
  max-width: 40px;
  border-radius: 3px;
}
