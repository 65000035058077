.switch-input {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
}

.switch {
  // variables
  --handle-size: 1rem;
  --padding: 1px;
  --border-width: 1px;

  // variables from html
  --active-color: attr(data-active type(<color>), #2C2C2C);
  --inactive-color: attr(data-inactive type(<color>), #757575);
  --accent-color: attr(data-accent type(<color>), #fff);


  align-items: center;
  cursor: pointer;
  display: inline-flex;
  // size
  padding: var(--padding);
  height: calc(var(--handle-size) + 2 * var(--padding) + 2 * var(--border-width));
  width: calc(2 * (var(--handle-size) + var(--padding) + var(--border-width)));
  // border
  border-radius: 10px;
  border-width: var(--border-width);
  border-style: solid;
  // transitions
  background-color: transparent;
  border-color: var(--inactive-color);
  transition: background-color 0.2s ease, border-color 0.2s ease;


  .switch__handle {
    border-radius: 50%;
    // size
    width: var(--handle-size);
    height: var(--handle-size);
    // transitions
    background-color: var(--inactive-color);
    transform: translateX(0);
    transition: transform 0.2s ease, background-color 0.2s ease;
  }


  &.switch--active {
    background-color: var(--active-color);
    border-color: var(--active-color);

    .switch__handle {
      background-color: var(--accent-color);
      transform: translateX(var(--handle-size));
    }
  }
}