.novelties-row {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.novelties-row__title {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 2.4rem;
}

@media (max-width: $screen-sm) {
  .novelties-row__title {
    font-size: 1.8rem;
  }
}
