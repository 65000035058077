.track {
  padding: 25px;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  transition: background-color ease-in-out .2s;
  scroll-margin-top: 130px; // Fixed header

  .track__main {
    display: flex;
    align-items: center;
  }

  &:before, &:last-of-type:after {
    width: calc(100% - 25px);
    height: 1px;
    position: absolute;
    top: 0;
    left: 50%;
    content: '';
    background: $border;
    transform: translateX(-50%);
  }

  &:last-of-type {
    margin-bottom: 40px;

    &:after {
      top: unset;
      bottom: 0;
    }
  }

  .cover {
    &:before {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      background-color: transparent;
      border-radius: 10px;
      transition: background-color ease-in-out .2s;
    }

    &:after {
      height: 38px;
      width: 38px;
      position: absolute;
      top: 50%;
      left: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Icomoon';
      font-size: 1.2rem;
      background-color: #fff;
      border-radius: 50%;
      box-shadow: $shadow--bottom;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity ease-in-out .2s;
    }
  }

  .title {
    margin: 0 0 2px;
    font-family: 'Aktiv Grotesque Bold', sans-serif;
    font-size: 1.6rem;
    color: $text;
    text-align: left;
  }
}

.track:hover,
.track--loading,
.track--active,
.track--paused,
.track--read {
  .cover {
    &:before { background-color: rgba(0, 0, 0, 0.2); }
    &:after { opacity: .85; }
  }
}

.track:hover {
  background-color: $background--dark;

  .cover:after { content: '\e908'; opacity: 1; }
}

.track--active, .track--active:hover {
  .cover:after { content: '\e907'; }
}

.track--paused,
.track--paused:hover {
  .cover:after { content: '\e908'; }
}

.track--loading,
.track--loading:hover {
  .cover:after { content: '\e912'; }
}

.track--read {
  .cover:after { content: '\e91f'; }

  .track__details {
    .title,
    .info { color: $text--lighter; }
  }

  .track__waveform {
    .waveform__path { stroke: $text--lighter; }
    .length { color: $text--lighter; }
  }
}

.track__details {
  margin-right: 20px;
  min-width: 250px;
  width: 300px;
  display: flex;
  align-items: center;

  .details {
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    color: $text--light;
  }

  .title {
    display: inline-flex;
    gap: 5px;
    align-items: center;
  }

  .projects {
    .icon {
      font-weight: bold;
      margin-right: 3px;
      font-size: 12px;
      color: $primary;
    }
  }
}

.track__waveform {
  margin-left: auto;
  margin-right: 20px;
  min-width: 0;
  display: flex;
  align-items: center;
  flex: 1;

  .seeker {
    cursor: pointer;
  }

  .waveform {
    flex: 1;
  }

  .length {
    margin-left: 30px;
    min-width: 40px;
    font-size: 1.5rem;
    color: $text--light;
  }
}

.track__actions {
  display: flex;

  .action-button,
  .dropleft {
    margin: 0 2px;

    &:last-child { margin-right: 0; }
    &:first-child { margin-left: 0; }
  }

  .dropover .dropover__menu > * {
    min-height: 24px;
  }
}

.track--cart {
  border-radius: 0;
  margin: 0;

  &:before {
    width: 100%;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: #f7f8fa;
  }

  .cover {
    &:before,
    &:after {
      content: none !important;
    }

    &:before { background-color: rgba(0, 0, 0, 0.2); }
    &:after { opacity: .85; }
  }

  .track__details {
    input[type="radio"] {
      display: inline;
      margin-bottom: 0;
      width: 18px;
    }

    .info {
      white-space: nowrap;
    }
  }

  .track__actions {
    .price {
      padding-right: 50px;
      font-weight: 600;
    }
  }

  .radio {
    margin: 0 30px;
  }
}

@media (max-width: $screen-lg) {
  .track {
    padding: 20px 10px;
    justify-content: space-between;
  }

  .track__details {
    margin-right: 0;
    max-width: calc(100% - 60px) !important;
    width: 100%;

    .details {
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .track__waveform {
    display: none;
  }

  .track--cart__actions {
    margin-top: 40px;
  }
}

@media (max-width: $screen-sm) {
  .track--cart {
    .track__main {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }

    .track__details {
      margin-right: 0;
      min-width: 200px;
      width: 100%;

      .details {
        white-space: initial;
      }
    }

    .track__actions {
      margin-top: 20px;
      justify-content: flex-end;
      width: 100%;

      .price {
        margin-right: auto;
      }
    }
  }
}
