.side-menu {
  @extend .list--raw;
  margin-top: 60px !important;
}

.side-menu__item {
  padding: 10px 0;

  .link {
    font-family: 'Aktiv Grotesque Bold', sans-serif;
    font-size: 1.8rem;
  }
}
