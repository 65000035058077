.searchbar {
  height: 70px;
  min-height: 70px;
  position: relative;
  display: flex;
  justify-content: space-between;
  background: #fff;
  box-shadow: $shadow--bottom;
  z-index: 200;
}

.searchbar__form {
  padding-left: 70px;
  padding-right: 30px;
  position: relative;
  display: flex;
  flex: 1;

  &:before {
    position: absolute;
    top: 50%;
    left: 45px;
    font-family: 'Icomoon';
    font-size: 2rem;
    content: '\e913';
    transform: translateY(-50%);
  }

  input {
    margin: 0 0 0 20px;
    padding: 0;
    height: 100%;
    flex: 1;
    font-family: 'Aktiv Grotesque Bold', sans-serif;
    font-size: 1.8rem;
    border: none;

    &::placeholder {
      font-family: 'Aktiv Grotesque Regular', sans-serif;
      color: $text--light;
    }

    &:focus {
      border: none;

      &::placeholder {
        color: lighten($text--light, 2%);
      }
    }
  }

  .clear-button {
    padding: 0 30px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    color: $text;
    font-size: 1.3rem;
    border: none;
    background: none;

    &:hover,
    &:active,
    &:focus {
      color: darken($text, 10%);
      transition: ease-in-out .15s;
      outline: none;
      box-shadow: none;

      .icon {
        border-color: darken($text, 10%);
        transition: ease-in-out .15s;
      }
    }

    .icon {
      border: solid 1px transparent;
      padding: 7px;
      border-radius: 50%;
    }
  }
}

.searchbar__button {
  padding: 0 25px;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  background: none;
  border: none;
  border-left: solid 1px $border;

  .pulse {
    margin-right: 10px;
  }
}

@media (min-width: $screen-lg) {
  .searchbar__button {
    &.filter-button {
      display: none;
    }
  }
}

@media (max-width: $screen-lg) {
  .searchbar__form {
    padding-left: 45px;
    padding-right: 65px;

    &:before {
      left: 20px;
    }

    input {
      width: 0;
      flex: 1;
    }

    button {
      padding: 0 20px;
    }
  }

  .searchbar__button {
    &.similarity-button {
      display: none;
    }
  }
}
