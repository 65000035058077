.checkbox {
  margin-bottom: 5px;
  display: flex;

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"] + label {
    margin: 0;
    padding-left: 30px !important;
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    color: $text;
    line-height: 20px;
    cursor: pointer;

    &:before {
      content: '';
      width: 20px;
      height: 20px;
      position: absolute;
      left: 0;
      background-color: #fff;
      border: solid 1px $border;
      border-radius: 5px;
    }

    &:after {
      content: '';
    }

    a {
      margin: 0 5px;
    }
  }

  input[type="checkbox"]:checked + label {
    &:before {
      background-color: $primary;
      border-color: $primary;
      transition: background-color ease-out .05s, border-color ease-out .05s;
    }

    &:after {
      width: 20px;
      height: 20px;
      position: absolute;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Icomoon';
      font-size: 1.1rem;
      color: #fff;
      content: '\e901';
      line-height: 1;
    }
  }

  input[type="checkbox"][disabled] {
    pointer-events: none;

    &:checked + label {
      pointer-events: none;

      &:before {
        background-color: lighten($primary, 10);
      }
    }

    & + label {
      &:before {
        background-color: $disabled;
      }
    }
  }
}

.checkbox--small {
  input[type="checkbox"] + label {
    font-size: 1.4rem;
    color: $text--light;

    &:before {
      border-color: $border--dark;
    }
  }
}
