.supports-choice {
  .checkbox {
    flex-wrap: wrap;
    align-items: center;
    min-height: 40px;

    input[type="checkbox"] + label {
      flex: 0;
      min-width: 200px;
    }

    input.support-extra {
      margin-bottom: 0;
      flex: 1;
    }

    .break {
      flex-basis: 100%;
    }

    .form__error {
      display: flex;
      margin: 10px 0;
      flex: 1;
    }
  }
}
