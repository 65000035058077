@font-face {
  font-family: 'Aktiv Grotesque Regular';
  src: url('../../fonts/aktiv-grotesk-regular.woff2') format('woff2'),
       url('../../fonts/aktiv-grotesk-regular.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

@font-face {
  font-family: 'Aktiv Grotesque Bold';
  src: url('../../fonts/aktiv-grotesk-bold.woff2') format('woff2'),
       url('../../fonts/aktiv-grotesk-bold.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
  font-display: swap;
}


@font-face {
  font-family: 'Poppins';
  src: url('../../fonts/poppins-regular.woff2') format('woff2'),
       url('../../fonts/poppins-regular.woff') format('woff');
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../../fonts/poppins-medium.woff2') format('woff2'),
       url('../../fonts/poppins-medium.woff') format('woff');
  font-style: normal;
  font-weight: 500;
  text-rendering: optimizeLegibility;
  font-display: swap;
}
