h2 {
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Aktiv Grotesque Bold', sans-serif;
  font-size: 4rem;
  color: $text;
  line-height: 1.3;

  &.left-title { align-items: flex-start; }
  &.right-title { align-items: flex-end; }

  span {
    font-size: 1.2rem;
    color: $primary;
    text-transform: uppercase;
  }
}

@media (max-width: $screen-md) {
  h2 {
    font-size: 2.4rem;
  }
}
