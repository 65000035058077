.backdrop {
  display: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #000;
  opacity: .2;
}

.backdrop--show {
  display: flex;
}

.backdrop--full {
  z-index: 300;
}
