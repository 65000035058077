.select {
  position: relative;
  display: flex;
  overflow: hidden;

  &:before {
    width: 30px;
    height: 100%;
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Icomoon';
    font-size: 1.2rem;
    color: $text--light;
    content: '\e902';
    cursor: pointer;
    pointer-events: none;
    background: $background;
  }

  select {
    cursor: pointer;
    appearance: none;
    font-size: 1.5rem;
    color: $text--light;
    background-color: transparent;
    border: none !important;
    outline: none;
    box-shadow: none;

    &:active,
    &:focus {
      border: none !important;
      outline: none;
    }

    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 $text--light;
    }

    &::-ms-expand,
    &:-moz-focusring { display: none; }
  }
}
