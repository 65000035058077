.title-block {
  margin: 0 10px 25px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  h1 {
    font-family: 'Aktiv Grotesque Bold', sans-serif;
    font-size: 2.4rem;
  }

  button:last-child {
    margin-right: 15px;
  }
}

.title-block__actions {
  padding-top: 5px;
  height: 55px;
  display: flex;
  align-items: flex-start;
}

.title-block__filters {
  height: 25px;
  display: flex;

  .filter-item {
    margin-left: 20px;
    padding-left: 20px;
    display: flex;
    align-items: center;
    border-left: solid 1px $border;

    &:first-of-type {
      padding-left: 0;
      border-left: none;
    }

    input {
      margin: 0;
      border: none !important;

      &::placeholder {
        opacity: 1;
      }
    }
  }

  .dropover__button {
    font-size: 1.6rem;
    color: $text--light;
  }
}

.title-block--tabs {
  margin-bottom: 35px;
  padding-bottom: 0;
  border-bottom: solid 1px $border;

  .title-block__filters {
    margin-bottom: 20px;
  }
}

@media (max-width: $screen-xxl) {
  .title-block {
    margin: 20px 10px;
    height: unset;

    h1 { margin: 0; }

    button:last-child {
      margin-top: -5px;
    }
  }

  .title-block--tabs {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
  }
}

@media (max-width: $screen-md) {
  .title-block {
    h1 { font-size: 1.8rem; }
  }
}
