.register-modal {
  --padding-size: 28px;
  display: flex;
  height: auto;
  width: 50vw;
  max-height: unset;
  max-width: 60rem;
  min-width: 30rem;
  background-color: rgba(255, 255, 255, 1);

  .modal__header,
  .modal__content {
    background-color: transparent;
  }

  .modal__header {
    height: auto;
    padding: 0;
    box-shadow: none;

    .close {
      transform: unset;
      right: calc(var(--padding-size) / 2);
      top: var(--padding-size);
      .icon {
        background: #fff;
      }
    }
  }

  .modal__content {
    padding: var(--padding-size);
    display: flex;
    flex-direction: column;
    font-size: 2.2rem;

    a {
      font-size: 1.8rem;
      color: #fff;
    }

    p:not(.small) {
      color: $text--light;
      strong {
        color: $text;
      }
    }

    p.small {
      font-size: 0.8em;
    }

    .btn {
      padding: 5px 10px;
      width: 100%;
      position: relative;
      justify-content: center;
      color: #fff;
      background: #4da5ff;
      margin-right: auto;
      margin-left: auto;
      border-radius: 10px;
    }
  }
}


@media (max-width: $screen-lg) {
  .register-modal {

    .modal__content {
      font-size: 1.6rem;
    }
  }
}

@media (max-width: $screen-md) {
  .register-modal {
    .modal__content {
      padding: var(--padding-size);
    }
  }
}
