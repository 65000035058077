.album {
  margin: 0 10px 25px;
  width: calc((100% / 5) - 20px);
  position: relative;
  display: flex;
  flex-direction: column;

  a {
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;

      .album__image {
        .image {
          transform: scale(1.05);
          transform-origin: center;

          &:before { opacity: 1; }
        }
      }

      .album__title {
        text-decoration: underline;
      }
    }
  }
}

.album__image {
  margin-bottom: 15px;
  width: 100%;
  position: relative;
  display: flex;
  border-radius: 5px;
  overflow: hidden;
  background-color: $background--dark;
  // prevent loss of border-radius during the hover animation on Safari
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  &:after {
    height: 38px;
    width: 38px;
    position: absolute;
    bottom: 0;
    left: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Icomoon';
    font-size: 1.2rem;
    color: $text;
    content: '\e908';
    background-color: #fff;
    border-radius: 50%;
    box-shadow: $shadow;
    transform: translate(-50%, -50%);
  }

  .image {
    width: 100%;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    transition: transform ease-in-out .4s;

    // responsive square
    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }

    &:before {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      background-color: rgba(0, 0, 0, .3);
      border-radius: 5px;
      opacity: 0;
      transition: opacity ease-in-out .4s;
    }
  }
}

.album__title {
  display: flex;
  font-family: 'Aktiv Grotesque Bold', sans-serif;
  font-size: 1.8rem;
  color: $text;
  hyphens: auto;
}

.album__info {
  max-width: 100%;
  font-family: 'Aktiv Grotesque Regular', sans-serif;
  font-size: 1.5rem;
  color: $text--light;
  hyphens: auto;
}

@media(max-width: $screen-lg) {
  .album {
    margin: 0 10px 40px !important;
    width: calc(50% - 20px);
  }
}

@media(max-width: $screen-sm) {
  .album {
    margin: 0 10px 30px !important;
    width: 100%;
  }

  .album__image {
    margin-bottom: 10px;
  }

  .album__title {
    font-size: 1.6rem;
  }
}
