.page-cart {
  .card {
    margin: 0 0 40px;
  }

  .track__details {
    margin-right: auto;
  }

  .track--cart {
    &:hover {
      background-color: #ffffff;
    }
  }
}

.page-cart__actions {
  margin-top: 40px;

  .mention {
    color: $text--light;
    margin: 20px 0;
  }
}

@media (max-width: $screen-sm) {
  .page-cart__actions {
    display: flex;
    flex-direction: column;

    .btn {
      display: flex;
      text-align: center;
      justify-content: center;
    }

    .btn + .btn {
      margin-left: 0;
      margin-top: 10px;
    }
  }
}
