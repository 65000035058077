.fixed-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 50;

  .is-impersonated & {
    top: 40px;
  }
}

.fixed-header + .main {
  margin-top: 130px;

  .is-impersonated & {
    margin-top: 170px;
  }
}

@media (max-width: $screen-md) {
  .fixed-header {
    .is-impersonated & {
      top: 120px;
    }
  }

  .fixed-header + .main {
    margin-top: 130px;

    .is-impersonated & {
      margin-top: 250px;
    }
  }
}
