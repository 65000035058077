.search-items {
  display: flex;
  align-items: center;
}

.search-items--item {
  margin: 0 0 0 20px;
  display: flex;
  align-items: center;
  font-family: 'Aktiv Grotesque Bold', sans-serif;
  font-size: 2rem;
  line-height: 1;

  button {
    margin-left: 10px;
  }
}

.search-items--remove {
  font-size: 1.3rem;
  color: $text--light;
}

.search-items--note {
  font-family: 'Aktiv Grotesque Bold', sans-serif;
  font-size: 2rem;
  color: $text--lighter;
}

.search-items--note + .search-items--item {
  margin-left: 10px;
}

@media (max-width: $screen-lg) {
  .search-items {
    display: none;
  }
}
