.hero {
  padding: 30px;
  color: #fff;
  background: $primary--light;
  border-radius: 4px;
}

.hero__title {
  margin: 0 0 30px;
}

.hero__content {
  p {
    margin: 0 0 30px;
  }

  .checkbox {
    input[type="checkbox"] + label {
      color: #fff;
    }
  }
}
