.collapsible {
  font-size: 1.4rem;
  position: relative;

  &:last-of-type {
    .collapsible__menu:before {
      display: none;
    }
  }

  .options {
    margin-left: 10px;
    display: flex;
    flex-wrap: nowrap;
  }

  .tag-button {
    flex: 1;
    color: $text--light;
    text-align: left;

    &:hover {
      color: $text;
    }
  }
}

.collapsible__actions {
  padding: 0 17px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.collapsible__menu {
  @extend .list--raw;
  max-height: 1px;
  position: relative;
  display: flex;
  flex-direction: column;
  //overflow: hidden;
  transition: max-height ease .15s;

  li {
    padding: 8px 45px 8px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.4rem;
    color: $text--light;

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      padding-bottom: 25px;
    }
  }

  .tag-button {
    padding: 0;
  }
}

.collapsible--show {
  .collapsible__menu {
    max-height: 100%;
    transition: max-height ease .15s;
  }
}
