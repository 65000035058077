.conditions {
  margin: 0 0 100px;
}

// Specific style if the PDF is properly rendered inlined in the page
.conditions__content.pdfobject-container {
  height: 300px;

  ~ label.conditions__button {
    border-radius: 0 0 10px 10px;
  }
}

.conditions__fallback_link {
  margin-bottom: 10px;
}

.conditions__input {
  display: none;
}

.conditions__button {
  margin: 0;
  height: 50px;
  padding-left: 30px !important;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  background: $primary--light;
  border-radius: 10px;

  .check {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: solid 1px $border;
    border-radius: 5px;

    &:after {
      content: '';
    }
  }
}

.conditions__input[disabled] + .conditions__button {
  background: $disabled;
  pointer-events: none;
}

.conditions__input:checked + .conditions__button {
  .check {
    background-color: $primary;
    border-color: $primary;
    transition: background-color ease-out .05s, border-color ease-out .05s;

    &:after {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Icomoon';
      font-size: 1.1rem;
      color: #fff;
      content: '\e901';
      line-height: 1;
    }
  }
}
