.card {
  margin-bottom: 40px;
  background-color: #fff;
  box-shadow: $shadow--bottom;

  .track {
    &:first-of-type {
      &:before { display: none; }
    }

    &:last-of-type {
      margin-bottom: 0;

      &:after { display: none; }
    }

    &:hover {
      background-color: $background;
    }
  }
}

.card__header {
  padding: 0 40px;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  box-shadow: $shadow--bottom;

  .info,
  .action {
    display: flex;
    align-items: center;
  }

  .title,
  .subtitle {
    font-family: 'Aktiv Grotesque Bold', sans-serif;
    font-size: 2.4rem;
  }

  .title {
    margin-right: 20px;
    padding-right: 20px;
    border-right: solid 1px $border;
  }

  .subtitle {
    color: $text--light;
    text-transform: uppercase;
  }

  .date {
    color: $text--light;
  }

  .action-button,
  .btn {
    margin-left: 7px;

    &:last-of-type {
      margin-left: none;
    }
  }
}

.card__content {
  padding: 45px 30px 30px;
}

.card--rounded {
  border-radius: 6px;
}

.card--licence {
  margin-top: -40px !important;
  border-top: 1px solid $border;

  &:hover {
    background-color: #fff !important;
  }
}

@media (max-width: $screen-lg) {
  .card {
    margin: 0 -20px;
    top: 0;
  }

  .card__header {
    padding: 0 20px;

    .title {
      margin: 0;
      padding: 0;
      font-size: 1.8rem;
      border: none;
    }

    .subtitle {
      display: none;
    }
  }

  .card__content {
    padding: 25px 20px;
  }
}

.card-similar {
  background-color: $background--dark;
  border-radius: 12px;
  padding: 25px 20px;
  margin: 10px 0 20px 0;

  &__title {
    font-family: 'Aktiv Grotesque Bold', sans-serif;
  }

  &__infos {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    & > div {
      display: flex;
      gap: 20px;
    }
  }

  &__tooltip {
    margin-left: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $border--dark;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__version {
    display: flex;
    gap: 20px;
  }

  &__play {
    width: 60px;
    height: 60px;
    min-width: 60px;
    border-radius: 10px;
    background-color: $primary;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Icomoon';
    color: white;
    .icon {
      margin-right: 0;
      font-size: 25px;
    }
  }

  &__wave {
    background-color: white;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
    & > svg {
      width: max-content;
    }
  }

  &__search {
    border-radius: 10px;
    background-color: $primary;
    color: white;
    padding: 1rem 2rem;
  }
}
