@font-face {
  font-family: 'Icomoon';
  src:  url('../../fonts/icomoon.eot');
  src:  url('../../fonts/icomoon.eot') format('embedded-opentype'),
        url('../../fonts/icomoon.ttf') format('truetype'),
        url('../../fonts/icomoon.woff') format('woff'),
        url('../../fonts/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  font-family: 'Icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$icons: (
  add: '\e900',
  arrow-left: '\e91d',
  arrow-right: '\e91e',
  bullet: '\e91a',
  cart: '\e921',
  check: '\e901',
  chevron-bottom: '\e902',
  chevron-left: '\e903',
  chevron-right: '\e904',
  chevron-top: '\e905',
  clear: '\e918',
  control-next: '\e906',
  control-pause: '\e907',
  control-play: '\e908',
  control-prev: '\e909',
  download: '\e90b',
  facebook: '\e90c',
  filter: '\e90d',
  forbid: '\e917',
  info: '\e90e',
  instagram: '\e90f',
  linkedin: '\e910',
  menu: '\e911',
  minus: '\e919',
  more: '\e912',
  picture: '\e91b',
  reload: '\e91f',
  search: '\e913',
  similar: '\e914',
  tag: '\e920',
  trash: '\e91c',
  twitter: '\e915',
  youtube: '\e916',
  eye: '\e90a',
  loop: '\ea2d',
  shuffle: '\ea30',
);

@each $icon-name, $icon-character in $icons {
  .icon--#{$icon-name}:before {
    content: $icon-character;
  }
}
