.clients {
  display: flex;
  flex-direction: column;
}

.clients__title { align-items: flex-start; }

.clients__list {
  @extend .list--raw;
  margin-left: 0 !important;
  width: 100%;
  display: flex;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: auto;

  &::-webkit-scrollbar {
    height: 0 !important;
    width: 0 !important;
  }

  li {
    margin: 30px 10px;
    height: 160px;
    width: 275px;
    min-width: 275px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 65px;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0 0 35px 1px rgba(5, 16, 43, .06);

    &:first-of-type { margin-left: 0; }
    &:last-of-type { margin-right: 0; }
  }
}

@media (max-width: $screen-lg) {
  .clients { margin-bottom: 50px; }
}

@media (max-width: $screen-sm) {
  .clients { margin-bottom: 20px; }

  .clients__list {
    li {
      height: 100px;
      width: 250px;
      min-width: 250px;
    }
  }
}
