.status-new {
    color: $primary--light;
}

.status-pending {
    color: $yellow;
}

.status-success {
    color: $green;
}
