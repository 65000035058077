.header {
  position: relative;

  .card {
    margin: 0;
  }

  .card__content {
    padding-left: 55px;
    padding-right: 55px;
  }
}

.header__content {
  display: flex;

  .image {
    margin: 0 80px 0 0;
    height: 270px;
    width: 270px;
    min-width: 270px;
    background-color: $border--light;
    border-radius: 10px;
    object-fit: cover;
  }

  .info {
    display: flex;
    flex-direction: column;
    color: $text--light;

    h1 {
      margin: 0 0 5px;
      min-width: 100px;
      font-family: 'Aktiv Grotesque Bold', sans-serif;
      font-size: 3.2rem;
      color: $text;
      letter-spacing: -.4px;

      &[contenteditable] {
        border-bottom: solid 1px transparent;

        &:focus {
          border-color: $text;
          outline: none;
          box-shadow: none;
        }
      }
    }

    .description {
      margin-top: 35px;
      font-size: 1.5rem;
      line-height: 1.7;

      p {
        margin: 0;
      }

      .btn {
        margin-top: 45px;
      }
    }

    > .btn {
      position: absolute;
      top: 25px;
      right: 20px;
    }
  }
}

.header__actions {
  margin: 20px 0;
  display: flex;
  justify-content: flex-end;
  gap: 5px;
}

.header--editable {
  h1 {
    margin: auto 0 260px;
  }
}

@media (max-width: $screen-lg) {
  .header {
    .card {
      margin: 0 -20px;
    }
  }

  .header__content {
    .image {
      margin: 0 40px 0 0;
      height: 190px;
      width: 190px;
      min-width: 190px;
    }

    h1 {
      margin: 0 0 10px;
      font-size: 2.8rem;
    }

    .btn {
      display: none;
    }
  }
}

@media (max-width: $screen-md) {
  .header__content {
    .image {
      display: none;
    }
  }

  .header__actions {
    justify-content: flex-start;
  }
}
