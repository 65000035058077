.table {
  margin: 45px 0;
  width: 100%;
  background: #fff;
  box-shadow: $shadow;
  overflow-x: auto;

  h2 + & {
    margin: 20px 0 45px;
  }

  .form,
  .form__input {
    margin-bottom: 0;
  }
}

table {
  width: 100%;

  caption {
    padding: 25px;
    font-family: 'Aktiv Grotesque Bold', sans-serif;
    color: $text;
    font-size: 1.6rem;
    caption-side: top;
  }

  tr {
    &:last-of-type {
      td { border-bottom: none; }
    }

    th,
    td {
      padding: 15px 25px;
      border: solid 1px $border;

      &:first-of-type { border-left: none; }
      &:last-of-type { border-right: none; }
    }

    th {
      font-size: 1.3rem;
      color: $text--light;
      font-weight: normal;
      text-transform: uppercase;
    }

    td {
      font-size: 1.5rem;
      color: $text;
    }
  }

  .align-right {
    text-align: right;
    white-space: nowrap;
  }

  .empty {
    text-align: center;
    color: $text--light;
  }
}
