.loader {
    width: 28px;
    height: 4px;
    background-image: url('../../images/loading.gif');
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    display: inline-block;
}

.loading {
    background-image: url('../../images/loading.gif');
    background-position: center !important;
    background-repeat: no-repeat !important;
    min-width: 28px;
    min-height: 4px;
}
