.pulse {
  height: 25px;
  display: flex;
  align-items: center;
}

.pulse__bar {
  margin: 0 1px;
  width: 2px;
  background: #000;
  display: flex;
  border-radius: 50px;
}

.pulse__bar--small {
  height: 35%;
  animation: pulsate-small 1.1s ease-in-out infinite both;
}

.pulse__bar--medium {
  height: 60%;
  animation: pulsate-medium 1.05s ease-in-out infinite both;
}

.pulse__bar--large {
  height: 100%;
  animation: pulsate 1s ease-in-out infinite both;
}

.pulse--large {
  margin: 10vh 0;
  height: 10vh;
  min-height: 140px;
  width: 100%;
  display: flex;
  justify-content: center;

  .pulse__bar {
    margin: 0 7px;
    width: 10px;
    background: var(--background--dark);
  }
}

@keyframes pulsate {
  0% { transform: scaleY(1); }
  25% { transform: scaleY(.75); }
  50% { transform: scaleY(1); }
  75% { transform: scaleY(.7); }
  100% { transform: scaleY(1); }
}

@keyframes pulsate-medium {
  0% { transform: scaleY(1); }
  25% { transform: scaleY(.8); }
  50% { transform: scaleY(1); }
  75% { transform: scaleY(.85); }
  100% { transform: scaleY(1); }
}

@keyframes pulsate-small {
  0% { transform: scaleY(1); }
  25% { transform: scaleY(.7); }
  50% { transform: scaleY(1); }
  75% { transform: scaleY(.75); }
  100% { transform: scaleY(1); }
}
