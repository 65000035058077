.filter-modal {
  .modal-body {
    margin: 0;
    padding: 0;
    display: flex;
  }

  .filter--panel {
    flex: 1;
    border-right: none;
  }

  .filter-option {
    margin: 0 17px;
    padding: 20px 0;
    width: unset;
    border-bottom: solid 1px $border;

    &:after {
      display: none;
    }
  }
}
