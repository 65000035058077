.input-range {
  margin: 15px 0 0;
  height: 20px;
  position: relative;
  width: 100%;
}

.input-range--disabled {
  .input-range__slider {
    background: $disabled;
    border: $disabled;
    box-shadow: none;
    transform: none;
  }

  .input-range__track {
    background: lighten($disabled, 10%);
  }
}

.input-range__label { display: none; } // Remove this (hides range numbers)

.input-range__slider {
  margin-left: -7px;
  margin-top: -12px;
  height: 15px;
  width: 15px;
  display: block;
  background: #fff;
  border: solid 1px $primary--light;
  border-radius: 100%;
  outline: none;
  appearance: none;
  cursor: pointer;
  transition: transform .15s ease-out, box-shadow .15s ease-out;

  &:active,
  &:focus {
    box-shadow: 0 0 0 5px rgba(77, 165, 255, .2);
  }
}

.input-range__slider-container {
  transition: left .3s ease-out;
}

.input-range__track {
  background: $border;
  border-radius: 50px;
  cursor: pointer;
  display: block;
  height: 8px;
  position: relative;
  transition: left .15s ease-out, width .15s ease-out;
}

.input-range__track--background {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  transform: translateY(-50%);
}

.input-range__track--active {
  background: $primary--light;
}
