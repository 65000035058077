.version {
  margin: 0 0 15px;
  padding: 0;
  width: 100%;
  display: flex;
  align-items: flex-start;
  font-size: 1.5rem;
  color: $text;
  text-align: left;
}

.version__title,
.version__ref,
.version__length {
  margin: 0px 5px;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
}

.version__title {
  width: calc(45% - 5px);
  display: flex;
  word-wrap: break-word;
  hyphens: auto;

  .icon {
    margin-right: 10px;
    height: 24px;
    min-height: 24px;
    min-width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    font-size: .9rem;
    color: $text;
    border-radius: 50%;
    border: solid 1px $border;
  }
}

.version__ref {
  color: $text--light;
  width: calc(35% - 10px);
}

.version__length {
  color: $text--light;
  width: calc(20% - 10px);
  text-align: right;
}

@media (max-width: $screen-sm) {
  .version {
    flex-wrap: wrap;
  }

  .version__title {
    width: 100%;
    margin: 0 0 5px;
  }

  .version__ref,
  .version__length {
    width: unset
  }

  .version__ref {
    margin-left: 35px;
  }
}
