.form {
  margin: 0 0 25px;
}

.form--inline {
  display: flex;
  flex-direction: row;
  align-items: center;

  .form__label { margin-right: 15px; }

  .form__unit {
    margin-left: 10px;
    font-size: 1.5rem;
    color: $text--light;
  }
}

.form__label {
  display: flex;
  font-size: 1.5rem;
  color: $text--light;

  .required {
    width: 10px;
    position: relative;

    &:before {
      position: absolute;
      top: 0;
      right: 0;
      color: $primary;
      content: '*';
    }
  }
}

.form__input,
.form__select {
  height: 38px;
}

.form__input,
.form__select,
.form__textarea {
  display: flex;
  width: 100%;
  align-items: center;
  background: transparent;
  border: solid 1px $border--dark;
  border-radius: 4px;
}

.form__input {
  margin: 0;
  padding: 0 15px;
}

.form__textarea {
  padding: 10px 15px;
  height: 160px
}

.form__select {
  padding-left: 15px;

  select {
    width: 100%;
  }
}

.form__help {
  display: flex;
  font-size: 1.25rem;
  color: $text--lighter;
}

.form__textarea + .form__help {
  justify-content: flex-end;
}

.form__select--error,
.form__input--error,
.form__textarea--error {
  border-color: $text--danger;

  &:focus {
    border-color: $text--danger;
  }
}

.form__error {
  font-size: 1.3rem;
  color: $text--danger;
}
