@keyframes slide-left {
  0% { opacity: 0; }

  100% {
    left: 0;
    opacity: 1;
  }
}

@keyframes slide-right {
  0% { opacity: 0; }

  100% {
    right: 0;
    opacity: 1;
  }
}


@keyframes slide-center {
  0% {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -53%);
    opacity: 0;
  }

  100% {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}
