.label-infos {
  margin-bottom: 50px;
  display: flex;
}

.label-infos__image {
  min-width: 300px;
  height: 300px;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: cover;
}

.label-infos__detail {
  margin-left: 60px;

  h1 {
    margin-top: 0;

    span {
      font-size: 40px;
      margin-left: 10px;
    }
  }

  p {
    color: $text--light;
  }
}

@media (max-width: $screen-md) {
  .label-infos {
    flex-direction: column;
  }

  .label-infos__image {
    min-width: 120px;
    max-width: 120px;
    height: 120px;
  }

  .label-infos__detail {
    margin-left: 0;
    margin-top: 25px;

    h1 {
      display: flex;
      flex-direction: column-reverse;

      span {
        margin-left: 0;
        font-size: 30px;
        line-height: 1;
      }
    }
  }
}
