.licence-type {
  padding: 25px;
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 0;
  transition: background-color ease-in-out .2s;
  scroll-margin-top: 130px;
  cursor: pointer;

  .title {
    margin: 0 0 2px;
    font-family: 'Aktiv Grotesque Bold', sans-serif;
    font-size: 1.6rem;
    color: $text;
    text-align: left;
  }

  &:before {
    width: 100%;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: #f7f8fa;
  }

  .licence-actions {
    margin-right: 35px;
  }

  .licence-type__main {
    display: flex;
    align-items: center;
  }

  .licence-type__input {
    margin-top: 30px;
  }

  .licence-type__details {
    margin-right: 20px;
    display: flex;
    align-items: center;
    flex: 1;

    input[type="radio"] {
      display: inline;
      margin-bottom: 0;
      width: 18px;
    }

    .details {
      display: flex;
      flex-direction: column;
      font-size: 1.5rem;
      color: $text--light;
    }
  }

  .licence-type__total {
    display: inline-flex;
    flex-direction: column;
    align-self: flex-end;
    text-align: right;
    margin-right: 35px;

    .price {
      padding-left: 65px;
    }

    .licence-type__price {
      padding-right: 0;
      margin-top: 25px;
      font-weight: 600;
    }
  }

  .radio {
    margin: 0 30px;
  }
}

.licence-type__actions {
  display: flex;
  margin: 30px 35px 0 0;

  .mention {
    color: $text--light;
    margin: 20px 0;
  }
}

.licence-type--mention {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 25px;
}

.licence-type--form {
  cursor: initial;

  &:hover,
  &:active,
  &:focus {
    background-color: #fff;
  }

  &:last-of-type {
    border-top: 1px solid $border;
  }
}

.licence-type--outline {
  margin-top: 24px;

  .btn {
    align-self: flex-start;
  }

  .mention {
    padding: 20px 0;
  }
}
