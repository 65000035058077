.about {}

.about__title {
  margin: 100px auto 35px;
  text-align: center;
}

.about__info {
  margin: 0 auto 60px;
  font-size: 1.8rem;
  color: $text--light;
  text-align: center;

  span {
    display: block;
  }
}

.about__content {
  @extend .card;
  @extend .card--rounded;

  margin: 50px auto 35px;
  max-width: 900px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: $screen-sm) {
  .about__info {
    span {
      display: unset;
    }
  }

  .about__content {
    flex-direction: column;
  }
}
