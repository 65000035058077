.track-tag-list--container {
    display: flex;
    margin: 20px 0 0 80px;
    font-size: 1.5rem;
    color: $text--light;

    > .icon {
        line-height: 1.7;
    }
}

.track-tag-list {
    display: block;
    list-style: none;
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
    padding-left: 15px;
    display: flex;
    flex-wrap: wrap;
}

.track-tag-button {
    display: flex;
    align-items: center;
    line-height: 24px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 0;
    position: relative;
    border: 1px solid transparent;

    .options {
        display: none;
        position: absolute;
        z-index: 1;
        left: 100%;
        border: 1px solid transparent;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        padding-left: 0.4em;
        background-color: white;
        border: 1px solid $border--dark;
        border-left: none;

        .options__item {
            border-left: 1px solid $border--dark;
            padding-left: 0.4em;
        }
    }

    .tag-button {
        padding-left: 0.4em;
        color: $text--light;
    }
}

.track-tag {
    display: flex;
    align-items: center;
    position: relative;

    &:not(:last-child):after {
        content: ', ';
    }

    &:hover {
        .track-tag-button {
            background-color: white;
            border-color: $border--dark;
        }

        .options {
            display: flex;
        }
    }
}
