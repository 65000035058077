.result-box {
  padding: 50px 20px 0;
  width: 100%;
  min-height: 200px;
  position: absolute;
  top: 130px;
  display: flex;
  flex-wrap: wrap;
  background: rgba(255, 255, 255, .96);
  z-index: 150;
}

.result-box--empty {
  color: $text--light;
  display: flex;
  align-items: center;
  justify-content: center;
}

.result-box__section {
  margin: 0 40px 70px;
  width: calc(25% - 80px);

  .title {
    padding-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: solid 1px $border;

    h3 {
      margin: 0;
      font-family: 'Aktiv Grotesque Bold', sans-serif;
      font-size: 1.8rem;

      span {
        color: $text--light;
      }
    }

    .link {
      font-family: 'Aktiv Grotesque Regular', sans-serif;
      font-size: 1.5rem;
    }
  }

  .collapsible {
    color: $text--light;
    border-bottom: solid 1px $border;

    &:last-of-type { border-bottom: none; }
  }

  .collapsible__actions {
    padding: 15px 0;
  }
}

.result-box__section--large {
  margin: 0 40px 50px;
  width: calc(100% - 80px);
}
