.alert {
  margin: 0 0 40px;
  padding: 15px 25px;
  color: #fff;
  background: $brand;
  border-radius: 5px;

  .icon {
    margin-right: 15px;
    font-size: 18px;
    line-height: 1.5;
  }
}

.alert--neutral {
  color: $text;
  background: $border;
}

.alert--error {
  background: $text--danger
}

.alert--brand {
  background: $brand
}

.alert--small {
  padding: 15px 20px;
  display: flex;
  font-size: 1.5rem;
  line-height: 1.7;
}

.alert--licence {
  margin-bottom: 80px;
}

.alert--fixed {
  margin: 0;
  padding: 0 25px;
  width: 100%;
  height: 40px;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 51;
  border-radius: 0;

  p {
    margin: 0;
  }

  a {
    margin-left: 30px;
    color: #fff;
    text-decoration: underline;

    &:hover,
    &:focus,
    &:active {
      color: #fff;
    }
  }
}

@media (max-width: $screen-md) {
  .alert--fixed {
    height: 120px;
    justify-content: center;
    text-align: center;

    a {
      margin: 7px 0 0;
      display: block;
      text-decoration: underline;
      border: none;
    }
  }
}
