

.subscription-banner {
  font-family: 'Poppins';
  padding: 4.5rem 5rem;
  height: auto;
  background-color: #2378C1;
  color: white;
  border-radius: 20px;
  
  h3 {
    font-weight: 700;
    font-size: 32px;
    margin: 0.5rem 0 1.2rem;
  }

  p {
    margin-bottom: 4rem;
  }

  &__footer {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  .btn {
    border-radius: 20px;
    display: flex;
    align-items: center;

    &:focus {
      border-color: black;
    }
  }

  .btn + .btn {
    margin-left: 0;
  }

  &:has(+ *) { // if the banner has a sibling after, add a margin
    margin-bottom: 2rem;
  }

  @media (max-width: $screen-md) {
    padding: 2.5rem;

    h3 {
      font-size: 28px;
    }

    &__footer {
      gap: 12px;

      .btn {
        padding: 0 16px;
        justify-content: center;
      }
    }
  }
}

.flex-1 {
  flex: 1;
}

.hidden {
  display: none;
}

.inline {
  display: inline;
}

@media (min-width: $screen-md) {
  .md\:hidden {
    display: none;
  }

  .md\:inline {
    display: inline;
  }

  .md\:flex-none {
    flex: none;
  }
}
