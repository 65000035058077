.forbidden-player-message {
  padding: 10px 10px 10px 20px;
  position: absolute;
  top: -20px;
  left: 50%;
  display: flex;
  align-items: center;
  background: white;
  border-radius: 40px;
  box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.15);
  transform: translateX(-50%) translateY(-100%);

  p {
    margin: 0;
    line-height: 1;
    white-space: nowrap;
  }

  button {
    padding: 10px;
    font-size: 1.4rem;
    color: $text--light;
    line-height: 1;
  }
}
