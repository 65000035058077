.sidepanel {
  padding: 35px 40px 0;
  width: 400px;
  background: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  z-index: 340;
  box-shadow: $shadow;
  overflow: auto;

  .subtitle {
    margin-bottom: 8px;
    display: flex;
    font-family: 'Aktiv Grotesque Bold', sans-serif;
    color: $text;
  }

  dl {
    display: flex;
    justify-content: space-between;
    padding: 0.4em;
    margin: 0 -0.4em;
    border-radius: 4px;

    &:hover {
      background-color: $background;
    }

    dt {
      font-weight: bold;
      margin: 0 1em 0 0;
      padding: 0;
    }

    dd {
      color: $text--light;
      text-align: right;
      margin: 0;
      padding: 0;
    }
  }

  .alert {
    margin-top: 20px;
  }

  hr {
    margin: 30px 0;
  }
}

.sidepanel__close {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 25px;
  right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  border: solid 1px $border;
  border-radius: 50px;
  transition: border-color ease-in-out .15s;

  &:hover,
  &:active,
  &:focus {
    border-color: $text;
  }

  .icon { margin: 0; }
}

.sidepanel__title {
  margin-bottom: 20px;
  padding-right: 35px;
  display: flex;
  font-family: 'Aktiv Grotesque Bold', sans-serif;
  font-size: 2rem;
  color: $text;
}

.sidepanel--sticky {
  overflow: hidden;

  .sidepanel__title {
    height: 4vh;
  }

  .sidepanel__subsection {
     height: calc(100% - 4vh);
  }

  .sidepanel__content,
  .sidepanel__section {
    height: 100%;
  }

  .project-list {
    padding: 20px 0;
    flex: 1;
    overflow: auto;
  }
}

.sidepanel__section {
  padding: 20px 0 25px;
  border-bottom: solid 1px $border;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }

  .sidepanel__subsection {
    margin: 20px 0;
    display: flex;
    flex-direction: column;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    @extend .list--raw;
  }

  .list {
    margin-bottom: 15px !important;
  }

  .credit {
    color: $text--light;
    margin-bottom: 0;
    text-transform: capitalize;

    li {
      margin: 0;
    }
  }

  .version {
    padding: 0.4em;
    margin: 0 -0.4em;
    border-radius: 4px;

    &:hover {
      background-color: $background;
    }

    &:hover,
    &.active {
      color: $primary !important;
      transition: color ease-in-out .15s;
      background-color: $background;

      .icon {
        color: $primary !important;
        border-color: $primary !important;
        transition: color ease-in-out .15s, border-color ease-in-out .15s;
      }
    }
  }

  .project-link {
    margin: 0 0 10px;
    padding: 0;
    position: relative;
    display: flex;
    align-items: center;
    color: $text;

    &:hover {
      color: $primary;
      transition: color ease-in-out .15s;
    }
  }

  .checkbox {
    margin-bottom: 17px;
  }

  .brief-list {
    margin: 20px 0;
  }

  .brief-add, .project-add {
    margin: 0 0 15px;

    .icon {
      height: 22px;
      width: 22px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: #fff;
      border-radius: 50px;
      background-color: $primary;
      transition: background-color ease-in-out .1s;
    }

    .icon--check {
      background-color: $success;
    }

    .text-danger {
      display: flex;
      margin: 10px 0 25px;
    }
  }
}

.sidepanel__footer {
  margin-top: auto;
  min-height: 100px;
  display: flex;
  align-items: center;
}

.sidepanel--left { left: -400px; }
.sidepanel--right { right: -400px; }

.sidepanel--show {
  &.sidepanel--left { animation: slide-left .4s ease-in-out both; }
  &.sidepanel--right { animation: slide-right .4s ease-in-out both; }
}

.sidepanel--with-player { bottom: 100px; }

@media (max-width: $screen-lg) {
  .sidepanel-open { overflow: hidden; }
}

@media (max-width: $screen-sm) {
  .sidepanel--show { width: 100%; }
}

