.action-button {
  width: 49px;
  height: 49px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: 1px solid $border--dark;
  transition: ease-in-out .15s;

  &:before,
  &:after { display: none !important; }

  &:hover,
  &:active,
  &:focus {
    color: $primary--light;
    border-color: $primary--light;
  }

  &.disabled,
  &[disabled] {
    border-color: $border-disabled;
  }

  .icon {
    margin: 0;
  }
}

.action-button__menu {
  padding: 20px 30px;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  box-shadow: $shadow--bottom;

  .link {
    margin: 0 0 10px;
    display: flex;
    font-size: 1.4rem;
    white-space: nowrap;

    &:last-of-type { margin-bottom: 0; }
  }
}

.action-button--primary {
  color: #fff;
  background-color: $primary--light;
  border-color: $primary--light;

  &:hover,
  &:active,
  &:focus {
    color: $primary--light;
    background-color: transparent;
  }

  &.disabled,
  &[disabled] {
    background-color: lighten($primary--light, 20%);
    border-color: lighten($primary--light, 20%);
  }
}

.action-button--danger {
  &:hover,
  &:active,
  &:focus {
    color: $danger;
    border-color: $danger;
  }
}

.action-button--active {
  color: $primary--light;
  border-color: $primary--light;
}

.action-button--selected {
  color: #fff !important ;
  background-color: $success !important;
  border-color: $success !important;

  &:hover,
  &:active,
  &:focus {
    color: #fff !important ;
  }
}

.action-button--disabled {
  color: #fff;
  background-color: lighten($primary--light, 20%);
  border-color: lighten($primary--light, 20%);
  cursor: default !important;

  &:hover,
  &:active,
  &:focus {
    color: #fff;
    background-color: lighten($primary--light, 20%);
    border-color: lighten($primary--light, 20%);
  }
}

.action-button--play,
.action-button--pause {
  &:hover,
  &:active,
  &:focus {
    color: initial;
    border-color: initial;
  }
}

.action-button--play {
  .icon:before { content: '\e908'; }
}

.action-button--pause {
  .icon:before { content: '\e907'; }
}

.action-button--loading {
  .icon:before { content: '\e912'; }
}

.action-button--cart {
  .icon:before { content: '\e921'; }
}
