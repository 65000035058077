html {
  height: 100%;
  font-size: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  display: flex;
  flex-direction: column;
  font-family: 'Aktiv Grotesque Regular', sans-serif;
  font-size: 1.6rem;
}

main {
  position: relative;
  display: flex;
  flex: 1;
  background-color: $background;
}

.content-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  padding-bottom: 80px;
  flex: 1;
  overflow: hidden;
}

.container { max-width: 1290px; }

.container-large { max-width: 2000px; }

.container-fluid {
  padding: 0 60px;
  max-width: 1500px;
}

@media (max-width: $screen-xxl) {
  .container-fluid {
    padding: 0 20px;
  }
}
