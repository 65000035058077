.breadcrumb {
  @extend .list--raw;
  margin: 40px auto 40px 10px !important;
  max-width: calc(100% - 200px);
  display: flex;
  font-size: 1.5rem;
}

.breadcrumb__item {
  display: flex;
  align-items: center;

  &:before {
    margin: 0 5px;
    content: '\e904';
    font-family: "Icomoon";
    font-size: .8rem;
    color: #fff;
  }

  &:first-of-type {
    &:before { display: none; }
    a { margin-left: 0; }
  }


  &:last-of-type {
    flex: 1;
    overflow: hidden;

    a {
      color: #fff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  a {
    margin: 0 10px;
    color: rgba(255, 255, 255, .6);
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      color: #fff;
      transition: color ease-in .15s;
    }
  }
}

.breadcrumb--dark {
  .breadcrumb__item {
    &:before {
      color: $text;
    }

    a {
      color: $text;

      &:hover,
      &:active,
      &:focus {
        color: darken($text, 10%);
      }
    }
  }
}

@media (max-width: $screen-sm) {
  .breadcrumb {
    margin: 40px auto 40px 10px !important;
  }
}
