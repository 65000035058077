.pagination {
  @extend .list--raw;
  margin: 0 auto !important;
  display: flex;
}

.pagination__item {
  a {
    width: 50px;
    height: 50px;
    color: $text;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    &:hover,
    &:active {
      color: $primary;
      text-decoration: none;
    }
  }

  &.active {
    a {
      pointer-events: none;
      border: solid 1px $border--dark;
    }
  }
}

.pagination__ellipsis {
  pointer-events: none;
}

@media (max-width: $screen-sm) {
  .pagination__item {
    a {
      width: 40px;
      height: 40px;
    }
  }
}
