.file-drop {
  height: 300px;
  position: relative;
  background-color: rgba(238,241,245,0.6);
  border: 2px dashed rgba(106,111,126,0.41);
  border-radius: 8px;

  &:hover, &.active {
    background-color: rgba(77,165,255,0.07);
    border-color: $primary;
  }
}

.file-drop--error {
  background-color: rgba(221, 71, 94, .1);
  border: 2px dashed $danger;

  &:hover, &.active {
    background-color: rgba(221, 71, 94, .2);
    border-color: $danger;
  }
}

.file-drop__input {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  border: none;
  opacity: 0;
  cursor: pointer;

  &:focus {
    border: none;
  }
}

.file-drop__label {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%);
  cursor: pointer;

  .image {
    margin-bottom: 10px;
    width: 55px;
    height: 70px;
    background: url('../../images/filedrop-image.png') center no-repeat;
    background-size: cover;
  }

  .loader {
    margin: 15px 0;
  }

  label {
    min-width: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    cursor: pointer;

    .label {
      margin: 0 0 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: 'Aktiv Grotesque Bold', sans-serif;
      font-size: 1.8rem;
      color: $text;
      line-height: 1.3;
    }
  }
}
