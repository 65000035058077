.offer {
  margin-bottom: 150px;
}

.offer__title {
  margin: 0 auto 45px;
  text-align: center;
}

.offer__content {
  display: flex;

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    text-align: center;

    &:last-of-type {
      .info { border: none; }
    }

    .info {
      padding: 0 80px;
      height: 130px;
      font-size: 1.6rem;
      color: $text--light;
      border-right: solid 1px $border;

      h3 {
        font-size: 2.4rem;
        color: $text;
      }
    }

    a {
      color: $primary--light;
      text-decoration: underline;
    }
  }
}

@media (max-width: $screen-lg) {
  .offer {
    margin-top: 50px;
  }

  .offer__content {
    margin: 0 0 30px;
    flex-direction: column;

    .item {
      flex-direction: row;
      justify-content: center;
      border-bottom: solid 1px $border;

      &:last-of-type { border-bottom: none; }

      img {
        margin: 30px 50px 30px 0;
        max-width: 200px;
      }

      .info {
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        text-align: left;
        border-right: none;
      }
    }
  }
}

@media (max-width: $screen-md) {
  .offer {
    margin-bottom: 35px;
  }
}

@media (max-width: $screen-sm) {
  .offer__content {
    margin: 0;

    .item {
      img { display: none; }

      .info {
        h3 {
          font-size: 1.8rem;
        }
      }
    }
  }
}
