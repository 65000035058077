.tile {
  margin: 0 10px 25px;
  width: calc(25% - 20px);
  position: relative;
  display: flex;
  flex-direction: column;

  a {
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;

      .tile__image {
        .image {
          transform: scale(1.05);
          transform-origin: center;

          &:before { opacity: 1; }
        }
      }

      .tile__title {
        text-decoration: underline;
      }
    }
  }

  .dropover {
    position: absolute;
    right: 0;
    top: 0;
  }

  .dropover__button {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(255,255,255, .7);
    transition: color ease-in-out .2s, text-shadow ease-in-out .2s;

    &:hover {
      color: rgba(255,255,255, 1);
      text-shadow: 0 0 15px rgba(0, 0, 0, .3);
    }
  }

  .dropover__menu {
    top: 15px;
    right: 15px;
  }
}

.tile__image {
  margin-bottom: 15px;
  height: 190px;
  width: 100%;
  position: relative;
  display: flex;
  border-radius: 5px;
  overflow: hidden;
  background-color: $background--dark;
  // prevent loss of border-radius during the hover animation on Safari
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  .image {
    height: 100%;
    width: 100%;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    transition: transform ease-in-out .4s;

    &:before {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      background-color: rgba(0, 0, 0, .3);
      border-radius: 5px;
      opacity: 0;
      transition: opacity ease-in-out .4s;
    }
  }
}

.tile__title {
  display: flex;
  font-family: 'Aktiv Grotesque Bold', sans-serif;
  font-size: 1.8rem;
  color: $text;
}

.tile__info {
  font-family: 'Aktiv Grotesque Regular', sans-serif;
  font-size: 1.5rem;
  color: $text--light;
}

.tile--small {
width: calc(16.5% - 20px);

  .tile__image {
    width: 190px;
  }
}

@media(max-width: $screen-lg) {
  .tile {
    margin: 0 10px 40px !important;
    width: calc(50% - 20px);
  }
}

@media(max-width: $screen-sm) {
  .tile {
    margin: 0 10px 30px !important;
    width: 100%;
  }

  .tile__image {
    margin-bottom: 10px;
  }

  .tile__title {
    font-size: 1.6rem;
  }
}
