.page-home {
  @import 'components/titles';
  @import 'components/about';
  @import 'components/clients';
  @import 'components/features';
  @import 'components/offer';
  @import 'components/services';
  @import 'components/register';

  background: $background;

  .white-background {
    background: #fff;
  }

  .blue-background {
    background: $brand;
  }

  .transition-wave-background {
    padding-bottom: 280px;
    background: url('../../../images/transition-background.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center bottom;
  }

  .transition-split-background {
    background: linear-gradient(to bottom, $background 0%, $background 50%, $brand 50%, $brand 100%);
  }
}

@media (max-width: $screen-lg) {
  .page-home {
    .transition-wave-background {
      padding-bottom: 150px;
    }
  }
}

@media (max-width: $screen-md) {
  .page-home {
    .transition-wave-background {
      padding-bottom: 100px;
    }
  }
}
