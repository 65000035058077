.similarity {}

.similarity__bar {
  height: 70px;
  position: relative;
  display: flex;
  background-color: #fff;
  box-shadow: $shadow--bottom;
  z-index: 150;

  .is-impersonated & {
    margin-top: 40px
  }

  .search-button {
    height: 100%;
    min-width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: solid 1px $border;

    .icon { font-size: 2rem; }
  }

  form {
    padding: 0 25px;
    width: 100%;
    display: flex;
    align-items: center;

    input {
      margin: 0;
      margin-left: 20px;
      padding: 0;
      height: 100%;
      flex: 1;
      font-family: 'Aktiv Grotesque Bold', sans-serif;
      font-size: 1.8rem;
      border: none;

      &::placeholder {
        font-family: 'Aktiv Grotesque Regular', sans-serif;
        color: $text--light;
      }

      &:focus {
        border: none;

        &::placeholder {
          color: lighten($text--light, 2%);
        }
      }
    }
  }
}

.similarity__box {
  padding: 55px 85px;
  display: flex;
  color: $text--light;
  background-color: rgba(255, 255, 255, .96);
  position: relative;
  z-index: 140;

  .help-text {
    margin-right: 75px;
    width: 250px;

    .title {
      margin-bottom: 20px;
      padding-bottom: 20px;
      display: flex;
      font-family: 'Aktiv Grotesque Bold', sans-serif;
      color: $text;
      font-size: 1.8rem;
      border-bottom: solid 1px $border--dark;
    }
  }

  .file-drop { flex: 1; }
}

@media (max-width: $screen-lg) {
  .similarity__box {
    padding: 30px;
    flex-direction: column;

    .help-text {
      width: 100%;
      margin: 0 0 20px;
    }

    .file-drop { flex: unset; }
  }
}

@media (max-width: $screen-md) {
  .similarity__bar {
    .is-impersonated & {
      margin-top: 100px
    }
  }
}

@media (max-width: $screen-sm) {
  .similarity__box {
    height: unset;
  }
}
