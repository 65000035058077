$brand: #104c81;
$primary: #2378c1;
$primary--light: #4da5ff;
$danger: #dd475e;
$success: #5dbe66;

$border: #e8e9ed;
$border-disabled: #e7e8eb;
$border--light: #f0f2f4;
$border--dark: #d0d1d5;
$background: #f7f8fa;
$background--dark: #eef1f5;
$tooltip: #3c3c3e;
$filters: #2c2c2c;

$waveform: #6a6e7e;
$waveform--played: #2378c1;
$waveform--disabled: #c0c1ca;

$text: #27282a;
$text--light: #6a6f7e;
$text--lighter: #aaafbc;
$text--danger: #e55e5d;
$text--success: #5dbe66;

$disabled: #cdd4dc;
$readonly: lighten($text, 20);
$bg-readonly: #f2f3f5;
$new: #fe7c2b;

$include: #104c81;
$exclude: #dd475e;

$screen-sm: map-get($grid-breakpoints, "sm"); // 576px
$screen-md: map-get($grid-breakpoints, "md"); // 768px
$screen-lg: map-get($grid-breakpoints, "lg"); // 992px
$screen-xl: 1120px;
$screen-xxl: 1350px;
